/***********************************************************************
 components.js contains shared UI components, objects, or packages 
 of each page's application of the MPA
************************************************************************/
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'animate.css';
import '../css/common.css';
import menuIcon from '../images/logos/evp_icon.jpg';
import clientLogo from '../images/logos/client_logo.png';
import loading from '../images/animations/loading.gif';
import _ from '../images/_.png';
import Help from '../images/Help.png';
import example1 from '../images/examples/example1.png';
import example2 from '../images/examples/example2.png';

let domain;

if (process.env.ENVIRONMENT === 'production') {
  // In production, use the environment variable
  domain = process.env.APIDOMAIN; 
} else {
  // In development, load from the local file
  const localEnv = require('../../ENV');
  domain = localEnv.domain;
}

function initApp() { // apply element place holders to the main section of the app element
    $('#app').html(`
        <div id="banner"></div>
        <div id="interface" class="mx-3 mt-2 justify-content-center"></div>
    `);
}

function announcement() {
    return `<div id="announcement" class="w-80 m-5">
    <p>Hello Everyone,</p>
    <p>
    Welcome to the Fall 2024 Students for a United and Resilient Europe or SURE program. Thank you for your participation in this effort designed to empower you to promote social cohesion in communities with refugees from Ukraine while pushing back against anti-Ukraine and Ukrainian narratives and disinformation being spread online and offline.
    Our goal during the SURE program is to ensure you have everything you need to be successful in completing the program while inspiring others with your creative and unique campaigns. We look forward to guiding your team through this process. As you begin the program, there are five action items you must be aware of. These action items should be considered a priority and need to be completed for your program to be considered officially "launched" allowing your team to move forward:
    </p>
    <ul style="list-style-type: disc;">
        <li><b>Roster: </b>A roster of participating students will need to be submitted containing first and last names as well as each student's email address.</li>
        <li><b>Registration: </b>Students will need to register for the program. This is a crucial part of the process confirming participation. Furthermore, should one student not register the entire team may be ineligible to participate. Registration can be found under Step 1.</li>
        <li><b>Role Selection: </b>There are three critical team roles that must be assigned. Once you have decided on these please email your Project Manager the name(s) of the student(s) fulfilling each role. Please note that these can be different individuals fulfilling these roles or one student taking on multiple responsibilities if they are capable of doing so.</li>
        <ul style="list-style-type: disc;">
        <li><i>Team Leader</i>: This will be the student contact for the team. This individual should be highly motivated, organized, and willing to take ownership of leading the team throughout the term. This is the student I will work closely with on project management.</li>
        <li><i>Budget Manager</i>: The Budget Manager will be responsible for filling in the budget request form, managing all program funds, filling out the expense log, and keeping track of all receipts. The Budget Manager can be you or a student who is enthusiastic about the role. The team will receive their funds later in the term via PayPal or paper check upon the completion of the Project Pitch Meeting</li>
        <li><i>Reports Manager</i>: his individual will be in charge of fulfilling the reporting requirements on behalf of the team. They will be tasked with program status reports and ensuring all of the deliverables have been submitted.</li>
        </ul>
        <li><b>Project Brief:</b> Be sure each team member reads the project brief. The project brief is the main guiding document for the program. The brief outlines the current challenge and objectives, how to approach the program, and program reporting and deliverable requirements necessary for your team to be eligible for the program competition. Once you have read the project brief, be sure to email your Project Manager any immediate questions you have about the program objectives or how to get started. The project brief is located under Step 1.</li>
        <li><b>Alumni Application:</b> By participating in the SURE program, you are eligible and invited to register to be part of the American Councils Global Alumni Network. Being a member of the global alumni network offers several benefits, resources, and future volunteer and program opportunities. The link to register is located under Step 1.</li>
    </ul>
    <p>Your Project Manager is available to support you in your next steps; please feel free to reach out with any questions. Thank you for your participation this semester; we can’t wait to see what you will create!</p>
    <p>Many thanks,</p>
    <p>Jordan Galkowski and Iuniana Mîndru<br />SURE Program Managers</p>
    </div>`;
}

function navbar() {
    $('#navigation').html(`
    <nav class="navbar navbar-dark bg-secondary" data-bs-theme="secondary">
        <div class="container-fluid">
            <a class="navbar-brand me-auto" href="index.html">
                <img src="${clientLogo}" alt="Logo" width="68" class="d-inline-block align-text-top rounded">
            </a>
            <button class="navbar-toggler collapsed" id="burger-icon" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
                <ul class="navbar-nav"></ul>
            </div>
        </div>
    </nav>
    `);
}

function navItems() {
    $('.navbar-nav').html(`<li class="nav-item ms-auto"><a class="nav-link active" aria-current="page" href="/">Home</a></li>`);
    $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link active" aria-current="page" href="timeline.html">Timeline</a></li>`);
    $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link active" href="team.html">Meet the Team</a></li>`);
    /* if(page !== "benefits") $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link" href="routes/#">Benefits of Participating</a></li>`);
    if(page !== "whois-evp") $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link" href="routes/#">Who is EVP</a></li>`);
    if(page !== "client") $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link" href="routes/#">Your Client</a></li>`);
    if(page !== "research") $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link" href="routes/#">Research Material</a></li>`);
    if(page !== "process") $('.navbar-nav').append(`<li class="nav-item ms-auto"><a class="nav-link" href="routes/#">The Process</a></li>`);
    $('.navbar-nav').append(`
        <li class="nav-item ms-auto dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" id="navbarDropdown" aria-expanded="false">
            Reach the experts
            </a>
            <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Project Manager #1</a></li>
                <li><a class="dropdown-item" href="#">Project Manager #2</a></li>
            </ul>
        </li>
    `); */
    burgerIcon();
}

/* Custom Menu Icon for the navigation */
function burgerIcon() {
    $('#burger-icon').html(`<img class="menu-icon rounded-circle" src="${menuIcon}">`);
}

/* Custom Foot for the portal */
function footer() {
    const year = new Date().getFullYear();
    $('#footer').html(`
    <footer class="p-3 mt-5 text-muted border-top text-center">
        <div id="logo-container"></div>
        <div>© <a class="link" href="https://edventurepartners.com">EdVenture Partners</a> ${year}</div>
        <div><a class="link" href="privacy_policy">Privacy Policy</a></div>
        <!-- <div>developed by <a class="link" href="https://www.github.com/chizuo">Jonathan Chua</a></div> -->
    </footer>
    `);
}

function interfaceLoading() {
    $('#interface').html(`<center><img id="floating-animation" src="${loading}"></center>`);
}

function pdf(path) {
    return `
    <div class="pdf-viewer animate__animated animate__zoomIn zoom">
        <object data="${path}" type="application/pdf" width="100%" height="100%">
            <p>This browser does not support inline PDFs. Please download the PDF to view it: <a href="${path}">Download PDF</a></p>
        </object>
    </div>
    `;
} 

function previous(view) {
    $('#return').on('click', view);
}

function returnButton() {
    return `<div class="text-center"><button id="return" class="m-2 btn btn-primary w-25"><i class="bi bi-arrow-left"></i> Return</button></div>`
};

function buttonFactory(elementID, buttonText, type='primary', elementType='div') {
    const element = elementType === "div" ? `<div class="text-center"><button id="${elementID}" class="m-5 btn btn-${type} w-25">${buttonText}</button></div>` : `<span class="text-center"><button id="${elementID}" class="m-5 btn btn-${type} w-25">${buttonText}</button></span>`
    return element;
}

function iconFactory(elementID, iconText, count) {
    let image = count === 1 ? example1 : example2;
    console.log(image);
    return `<div class="col-sm-4 g-0 m-2 animate__animated animate__lightSpeedInLeft icon-15 glow-button">
        <div id="${elementID}" class="square-container-normal">
            <img class="fill icon-15 dark-33" src="${image}">
            <div class="overlay-text text-center display-4">${iconText}</div>
        </div>
    </div>`
}

function helpElement(direction = 'left') {
    let animation = direction == 'left' ? 'animate__backInLeft' : 'animate__backInRight';
    return `<div id="help" class="col-sm-4 g-0 m-2 animate__animated ${animation} icon-15 glow-button ${direction}">
                <div class="square-container-normal">
                    <img class="fill icon-15" src="${Help}"> 
                </div>
            </div>`;
}

function init(participant, main, location) {
    if(participant === null) {
        window.location.href = 'login';
    } else if(participant.tracked.step1["Watch kickoff video"] === 0 && participant.type == "student" && location !== "homepage") {
        window.location.href = '/';
    } else {
        initApp();
        main();
    }
}

export {
    $,
    announcement,
    navbar,
    navItems,
    footer,
    menuIcon as evpIcon,
    domain,
    loading,
    helpElement as Help,
    iconFactory,
    init,
    initApp,
    interfaceLoading,
    description,
    pdf,
    previous,
    resource,
    returnButton,
    buttonFactory,
};
